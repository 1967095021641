// passwordReset.ts
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import firebase_app from "../config";

interface PasswordResetResult {
  success: boolean;
  error?: string;
}

export async function sendPasswordReset(email: string): Promise<PasswordResetResult> {
  const auth = getAuth(firebase_app);

  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true };
  } catch (error: any) {
    console.error("Error sending password reset email:", error);
    return { success: false, error: error.message || "An unknown error occurred" };
  }
}
