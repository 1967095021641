import { FirebaseError } from "firebase/app";
import firebase_app from "../config";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getFirestore, getDoc, collection, query, where, getDocs } from "firebase/firestore";

const auth = getAuth(firebase_app);
const firestore = getFirestore(firebase_app);

interface CustomAuthError {
  code: string;
  message: string;
}

// Helper function to check if user exists in Firestore
async function userExists(email: string): Promise<boolean> {
  //   const usersRef = firestore.collection("users");
  const usersRef = collection(firestore, "users");
  const q = query(usersRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty;
}

export default async function signInOrSignUp(
  email: string,
  password: string
): Promise<{ result: any; error: CustomAuthError | null }> {
  let result = null;
  let error: CustomAuthError | null = null;

  try {
    // Check if user exists in Firestore
    const exists = await userExists(email);
    if (exists) {
      // If user exists, attempt to sign in
      result = await signInWithEmailAndPassword(auth, email, password);
    } else {
      // If user doesn't exist, proceed to sign up
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      result = userCredential;

      // Add new user to Firestore
      const userRef = doc(firestore, "users", userCredential.user.uid);
      await setDoc(userRef, {
        email: userCredential.user.email,
        userId: userCredential.user.uid,
        credits: 3,
      });
    }
  } catch (e) {
    if (e instanceof FirebaseError) {
      // Handle FirebaseError
      error = { code: e.code, message: e.message };
    } else {
      // Handle unexpected errors
      error = { code: "unknown_error", message: "An unknown error occurred" };
    }
  }

  return { result, error };
}
