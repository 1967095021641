import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import firebase_app from "../config";
import generateUniqueUsername from "./generateUniqueUsername";
import { createUserSsr } from "@/helpers/clientApis";

interface SignInWithGoogleResult {
  result?: {
    user: any;
    credential: any;
  };
  error?: {
    code: string;
    message: string;
  };
}

export async function signInWithGoogle(
  onSavingComplete: (uidOverride: string) => void
): Promise<SignInWithGoogleResult> {
  const auth = getAuth(firebase_app);
  const firestore = getFirestore(firebase_app);
  const provider = new GoogleAuthProvider();

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    // Check if the user already exists in Firestore
    const userRef = doc(firestore, "users", user.uid);
    const docSnap = await getDoc(userRef);

    // If the user doesn't exist, create a new document
    if (!docSnap.exists()) {
      const extraUserData = {
        email: user.email,
        userId: user.uid,
        credits: 5,
      };

      await setDoc(userRef, extraUserData);
      onSavingComplete(user.uid);
    }

    return { result: { user, credential: GoogleAuthProvider.credentialFromResult(result) } };
  } catch (error: any) {
    return { error: { code: error.code, message: error.message } };
  }
}
