"use client";
import { signInWithGoogle } from "@/firebase/auth/signInWithGoogle";
import { DEFAULT_MEDIA_QUERY } from "@/theme";
import {
  Anchor,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Group,
  Modal,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";

import { ERROR_CODES } from "@/constants";
import { useAuthContext } from "@/context/authContext";
import { sendPasswordReset } from "@/firebase/auth/sendPasswordReset";
import signUp from "@/firebase/auth/signUp";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useRouter } from "next/navigation";
import { useState } from "react";
import GoogleButton from "./GoogleButton";
import TwitterButton from "./TwitterButton";

export default function LoginPageContent() {
  const router = useRouter();
  const isMobile = useMediaQuery(DEFAULT_MEDIA_QUERY);
  const [openModal, setOpenModal] = useState(false);
  const [emailForReset, setEmailForReset] = useState("");
  const { refreshUser } = useAuthContext();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) => (val.length <= 6 ? "Password should include at least 6 characters" : null),
    },
  });

  const handlePasswordResetSubmit = async () => {
    const { success, error } = await sendPasswordReset(emailForReset);
    if (success) {
      notifications.show({ title: "Success", message: "Password reset email sent.", color: "green" });
      setOpenModal(false); // Close the modal after successful operation
    } else {
      notifications.show({ title: "Error", message: error || "Failed to send password reset email.", color: "red" });
    }
  };

  const handleGoogleSignIn = async () => {
    const { result, error } = await signInWithGoogle(refreshUser);
    if (result) {
      router.push("/dashboard");
    } else if (error) {
      notifications.show({
        title: "Error signing in",
        autoClose: 5000,
        message: error.message,
        color: "red",
      });
    }
  };

  const handleSubmit = async () => {
    const { email, password } = form.values;
    const { result, error } = await signUp(email, password);
    if (result) {
      router.push("/dashboard");
    } else if (error) {
      const errorMsg = ERROR_CODES[error.code];
      notifications.show({
        title: "Error logging in",
        color: "red",
        message: errorMsg || error.message,
      });
    }
  };

  return (
    <Container size="xl" display="flex" style={{ justifyContent: "center" }} mih="40vh" my="4rem">
      <Flex align="center" justify="center">
        <Paper radius="md" p="xl" withBorder miw={!isMobile ? "500px" : undefined} shadow="md" ta="center">
          <Text size="lg" fw={500}>
            Welcome to KidPrints!
          </Text>

          <Flex justify="center">
            <GoogleButton onClick={handleGoogleSignIn} fullWidth mt="md" maw="400px">
              Use Your Google Account
            </GoogleButton>
          </Flex>

          <Divider label="First time here?" labelPosition="center" mt="lg" mb="xs" />
          <Text ta="center" size="xs" pt="0" mt="0">
            You get a few free credits when you make an account, no payment required..
          </Text>

          {/* <Divider label="Or continue with email" labelPosition="center" my="lg" />
          <Box ta="left" px="md">
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Stack>
                <TextInput
                  required
                  label="Email"
                  placeholder="superawesomedinosaur@email.com"
                  value={form.values.email}
                  onChange={(event) => form.setFieldValue("email", event.currentTarget.value)}
                  error={form.errors.email && "Invalid email"}
                  radius="md"
                />

                <PasswordInput
                  required
                  label="Password"
                  placeholder="Your password"
                  value={form.values.password}
                  onChange={(event) => form.setFieldValue("password", event.currentTarget.value)}
                  error={form.errors.password && "Password should include at least 6 characters"}
                  radius="md"
                />
              </Stack>

              <Group justify="space-between" align="center" mt="md">
                <Anchor component="button" type="button" size="sm" onClick={() => setOpenModal(true)}>
                  Forgot password?
                </Anchor>

                <Modal opened={openModal} onClose={() => setOpenModal(false)} title="Reset password">
                  <Text size="sm" c="dimmed">
                    Enter your email address to receive a password reset link.
                  </Text>
                  <TextInput
                    label="Email"
                    placeholder="yourEmail@example.com"
                    value={emailForReset}
                    onChange={(event) => setEmailForReset(event.currentTarget.value)}
                    mt="md"
                  />
                  <Button onClick={handlePasswordResetSubmit} mt="md">
                    Send Reset Link
                  </Button>
                </Modal>
                <Button type="submit">Enter</Button>
              </Group>
            </form>
          </Box> */}
        </Paper>
      </Flex>
    </Container>
  );
}
